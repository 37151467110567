/**
 * @license
 *
 * Font Family: Gambarino
 * Designed by: Théo Guillard
 * URL: https://www.fontshare.com/fonts/gambarino
 * © 2023 Indian Type Foundry
 *
 * Font Style:
 * Gambarino Regular
 *
*/

@font-face {
  font-family: 'Gambarino-Regular';
  src: url('../fonts/Gambarino-Regular.woff2') format('woff2'),
       url('../fonts/Gambarino-Regular.woff') format('woff'),
       url('../fonts/Gambarino-Regular.ttf') format('truetype');
       font-weight: 400;
       font-display: swap;
       font-style: normal;
}

@import url('https://api.fontshare.com/v2/css?f[]=switzer@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oranienbaum&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oranienbaum&family=Playfair+Display&display=swap');