.work {
  max-width: 660px;
  max-height: 962px;
  column-count: 2;
  column-gap: 12px;
  margin: 0 0 0 -28px;

  @media screen and (max-width: 1440px) {
    max-height: 1140px;
  }

  @media screen and (max-width: 1024px) {
    max-height: none;
  }

  @media screen and (max-width: 768px) {
    max-width: none;
    max-height: none;
    margin: 24px 0 0;
  }

  @media screen and (max-width: 430px) {
    column-count: 1;
    max-width: none;
  }


  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin: 0;
    margin-bottom: 10px;
    break-inside: avoid;
    max-width: 268px;
    border-radius: 24px;
    background: #F8F8F8;
    padding: 36px 28px;

    @media screen and (max-width: 768px) {
      max-width: none;
      padding: 34px 30px;
    }

    @media screen and (max-width: 400px) {

    }
  }

  &__img {
    max-height: 36px;
    margin-bottom: 24px;
  }

  &__title {
    color: #000;
    font-family: 'Gambarino-Regular', serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 125% */
    margin: 0;

    &[lang="en"] {
      font-family: 'Gambarino-Regular', serif;
    }

    &[lang="ru"] {
      font-family: 'Playfair Display', serif;
    }
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  &__text {
    color: #000;
    font-family: 'Switzer', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 175% */
    margin: 0;

    &[lang="en"] {
      font-family: 'Switzer', Helvetica, Arial, sans-serif;
    }

    &[lang="ru"] {
      font-family: Helvetica, Arial, sans-serif;
    }

    &_grey {
      color: var(--CBCBCB, #CBCBCB);
    }
  }

  &__link {
    color: var(--CBCBCB, #CBCBCB);
    text-decoration: none;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding-bottom: 4px;
    transition: .4s;
  }

  &__link:hover {
    color: #FF76F1;
    border-bottom: 1px solid rgba(255, 118, 192, .1);
  }

}