.language-toggle__button {
  color: #CBCBCB;
  font-family: 'Switzer', Helvetica, Arial, sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px; /* 169.231% */
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: 80px;
  position: fixed;
  left: 56px;
  bottom: 48px;
  background: none;
  border: none;
  transition: .4s;

  &[lang="en"] {
    font-family: 'Switzer', Helvetica, Arial, sans-serif;
  }

  &[lang="ru"] {
    font-family: Helvetica, Arial, sans-serif;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: flex-end;
    font-size: 20px;
    line-height: 36px; /* 180% */
    padding: 0;
    height: 64px;
    bottom: 24px;
    right: 30px;
  }

  &:hover {
    color: #FF76F1;
    cursor: pointer;
  }
}