.education {
  display: flex;
  flex-wrap: wrap;
  max-width: 672px;
  gap: 88px;

  @media screen and (max-width: 1024px) {

  }

  @media screen and (max-width: 768px) {
    max-width: none;
    margin: 40px 0 0;
    padding: 0 30px;
    gap: 72px;
  }

  @media screen and (max-width: 400px) {
    gap: 40px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    max-width: 248px;

    @media screen and (max-width: 768px) {
      width: calc((50% - 36px));
      max-width: none;
    }

    @media screen and (max-width: 430px) {
      width: 100%;
    }
  }

  &__img {
    max-height: 36px;
    margin-bottom: 24px;

    @media screen and (max-width: 400px) {
      margin-bottom: 8px;
    }
  }

  &__title {
    color: #000;
    font-family: 'Gambarino-Regular', serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 125% */
    margin: 0;
    max-width: 240px;

    &[lang="en"] {
      font-family: 'Gambarino-Regular', serif;
    }

    &[lang="ru"] {
      font-family: 'Playfair Display', serif;
    }

    @media screen and (max-width: 400px) {
      font-size: 28px;
      line-height: 34px; /* 121.429% */
    }
  }

  &__text {
    color: #000;
    font-family: 'Switzer', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 175% */
    margin: 0;

    &[lang="en"] {
      font-family: 'Switzer', Helvetica, Arial, sans-serif;
    }

    &[lang="ru"] {
      font-family: Helvetica, Arial, sans-serif;
    }
  }

  &__link {
    color: rgba(255, 255, 255, .3);
    text-decoration: none;
    margin: 0;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    cursor: none;
    transition: .4s;
  }

  &__link:hover {
    color: rgba(255, 118, 192, 1);
    border-bottom: 1px solid rgba(255, 118, 192, .3);
  }

}