.footer {
  display: inline-flex;
  flex-direction: column;
  align-items: baseline;
  max-width: 1120px;
  width: 100%;

  @media screen and (max-width: 768px) {

  }

  @media screen and (max-width: 400px) {
    padding-bottom: 56px;
  }

  &__subtitle {
    color: var(--CBCBCB, #CBCBCB);
    font-family: 'Switzer', Helvetica, Arial, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px; /* 169.231% */
    max-width: 392px;
    min-width: 200px;
    width: 100%;
    margin: 0;

    &[lang="en"] {
      font-family: 'Switzer', Helvetica, Arial, sans-serif;
    }

    &[lang="ru"] {
      font-family: Helvetica, Arial, sans-serif;
    }

    @media screen and (max-width: 768px) {
      font-size: 20px;
      line-height: 36px; /* 180% */
      padding: 0 30px;
    }

    &_white {
      color: #fff;
    }
  }

  &__text {
    color: #000;
    font-family: 'Switzer', Helvetica, Arial, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px; /* 169.231% */
    margin: 0;

    &[lang="en"] {
      font-family: 'Switzer', Helvetica, Arial, sans-serif;
    }

    &[lang="ru"] {
      font-family: Helvetica, Arial, sans-serif;
    }

    @media screen and (max-width: 768px) {
      font-size: 20px;
      line-height: 36px; /* 180% */
    }

    &_grey {
      color: var(--CBCBCB, #CBCBCB);
    }

    &:first-child {
      margin-bottom: 40px;
    }

    @media screen and (max-width: 768px) {

    }
  }

  &__social-links {
    display: flex;
    flex-direction: column;
    max-width: 728px;

    @media screen and (max-width: 768px) {
      padding: 0 30px;
    }
  }

  &__link {
    color: #000;
    font-family: 'Switzer', Helvetica, Arial, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px; /* 169.231% */
    max-width: 728px;
    margin: 0;
    text-decoration: none;
    transition: .3s ease;

    &[lang="en"] {
      font-family: 'Switzer', Helvetica, Arial, sans-serif;
    }

    &[lang="ru"] {
      font-family: Helvetica, Arial, sans-serif;
    }

    @media screen and (max-width: 768px) {
      font-size: 20px;
      line-height: 36px; /* 180% */
    }
  }

  &__link:hover {
    color: #FF76F1;
  }

}