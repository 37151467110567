::selection {
  background-color: #E9CDFF;
  color: #fff;
}

.main-page {
  padding: 160px 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 160px;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    padding: 56px 0;
    gap: 72px
  }

}

.content {
  display: inline-flex;
  align-items: baseline;
  max-width: 1120px;
  width: 100%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  &_column {
    flex-direction: column;
    gap: 56px;
  }

  &_flex-start {
    align-items: flex-start;
  }

  &__title {
    color: #000;
    font-family: 'Gambarino-Regular', serif;
    font-size: 80px;
    font-style: normal;
    font-weight: 400;
    line-height: 90px; /* 112.5% */
    margin: 0;

    &[lang="en"] {
      font-family: 'Gambarino-Regular', serif;
    }

    &[lang="ru"] {
      font-family: 'Playfair Display', serif;
    }

    @media screen and (max-width: 768px) {
      font-size: 66px;
      line-height: 74px; /* 127.778% */
      padding: 0 30px;
    }

    @media screen and (max-width: 440px) {
      font-size: 36px;
      line-height: 46px; /* 127.778% */
    }

    &_gradient {
      background: var(--1, linear-gradient(90deg, #B75BFF 12.02%, #FF3BB0 67.83%));
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &__subtitle {
    color: var(--CBCBCB, #CBCBCB);
    font-family: 'Switzer', Helvetica, Arial, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px; /* 169.231% */
    max-width: 392px;
    min-width: 200px;
    width: 100%;
    margin: 0;

    &[lang="en"] {
      font-family: 'Switzer', Helvetica, Arial, sans-serif;
    }

    &[lang="ru"] {
      font-family: Helvetica, Arial, sans-serif;
    }

    &_mt {
      margin-top: 77px;

      @media screen and (max-width: 768px) {
        margin-top: 0;
      }
    }

    @media screen and (max-width: 1024px) {
      max-width: 232px;
    }

    @media screen and (max-width: 768px) {
      font-size: 20px;
      line-height: 36px; /* 180% */
      padding: 0 30px;
    }

    &_white {
      color: #fff;
    }
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media screen and (max-width: 768px) {
      gap: 0;
    }
  }

  &__link-container {
    display: flex;
    gap: 8px;

    @media screen and (max-width: 768px) {
      padding: 0 30px;
    }
  }

  &__text {
    color: #000;
    font-family: 'Switzer', Helvetica, Arial, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px; /* 169.231% */
    max-width: 728px;
    margin: 0;

    &[lang="en"] {
      font-family: 'Switzer', Helvetica, Arial, sans-serif;
    }

    &[lang="ru"] {
      font-family: Helvetica, Arial, sans-serif;
    }

    &_grey {
      color: var(--CBCBCB, #CBCBCB);

      @media screen and (max-width: 768px) {
        padding: 0!important;
      }
    }

    @media screen and (max-width: 768px) {
      font-size: 20px;
      line-height: 36px; /* 180% */
      padding: 0 30px;
    }
  }

  &__link {
    color: #000;
    font-family: 'Switzer', Helvetica, Arial, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px; /* 169.231% */
    max-width: 728px;
    margin: 0;
    text-decoration: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 0px;
    transition: 0.4s;

    &[lang="en"] {
      font-family: 'Switzer', Helvetica, Arial, sans-serif;
    }

    &[lang="ru"] {
      font-family: Helvetica, Arial, sans-serif;
    }

    @media screen and (max-width: 768px) {
      font-size: 20px;
      line-height: 36px; /* 180% */
    }
  }

  &__link:hover {
    color: #FF76F1;
    border-bottom: 2px solid rgba(255, 118, 192, .1);
  }

}

.apply {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  position: fixed;
  right: 56px;
  bottom: 48px;
  width: 240px;
  height: 80px;
  flex-shrink: 0;
  border-radius: 40px;
  border: none;
  background: rgba(255, 255, 255, 0);
  overflow: hidden;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, .05);
  transition: .4s;

  @media screen and (max-width: 768px) {
    width: 200px;
    height: 64px;
    bottom: 24px;
    right: auto;
  }

  &::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .8);
    filter: blur(4px);
    backdrop-filter: blur(4px);
    z-index: -1;
  }

  & span {
    font-family: 'Switzer', Helvetica, Arial, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px; /* 169.231% */
    background: var(--1, linear-gradient(90deg, #B75BFF 12.02%, #FF3BB0 67.83%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: 1;
    overflow: hidden;
    transition: .4s;

    &[lang="en"] {
      font-family: 'Switzer', Helvetica, Arial, sans-serif;
    }

    &[lang="ru"] {
      font-family: Helvetica, Arial, sans-serif;
    }

    @media screen and (max-width: 768px) {
      font-size: 20px;
      line-height: 36px; /* 180% */
    }
  }

  &:hover {
    background-color: #fff;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, .1);
    cursor: pointer;

    & span {
      background: var(--1, linear-gradient(90deg, #B75BFF -79.31%, #FF3BB0 36.9%));
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &:active {
    transform: scale(0.98);
    transition: transform 0.2s ease;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, .15);
    transition: box-shadow 0.2s ease;
  }
}
