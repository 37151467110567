.logos {
  max-width: 1400px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px 0;

  @media screen and (max-width: 768px) {
    max-width: none;
  }

  &__img {


    @media screen and (max-width: 768px) {
      max-width: 172px;
    }
  }
}