html {
  font-family: 'Switzer', Helvetica, Arial, sans-serif;
  height: 100%;

  &[lang="en"] {
    font-family: 'Switzer', Helvetica, Arial, sans-serif;
  }

  &[lang="ru"] {
    font-family: Helvetica, Arial, sans-serif;
  }
}

